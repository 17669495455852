/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import Header from '../komponents/Header/Header';
import Footer from '../komponents/Footer/Footer';

const Login = () => (
  <>
    <Header />

    <Footer />
  </>
);

export default Login;

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content="Tienda en linea - PuntoTerminal" />
    <title>Tienda en linea - PuntoTerminal.mx</title>
  </>
);
